import React, { useState } from 'react';
import axios from 'axios';
import AdminLogin from '../components/AdminLogin';
import Modal from 'react-modal';

// Defina o elemento raiz para o modal (necessário para acessibilidade)
Modal.setAppElement('#root');

function AdminPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fridayEvent, setFridayEvent] = useState({
    day: 'friday',
    active: false,
    flyer: "",
    pistaPrice: 0,
    camarotePrice: 0,
    camaroteOpenPrice: 0,
    pistaActive: false,
    camaroteActive: false,
    camaroteOpenActive: false
  });
  const [saturdayEvent, setSaturdayEvent] = useState({
    day: 'saturday',
    active: false,
    flyer: "",
    pistaPrice: 0,
    camarotePrice: 0,
    camaroteOpenPrice: 0,
    pistaActive: false,
    camaroteActive: false,
    camaroteOpenActive: false
  });
  const [comingSoonEvent, setComingSoonEvent] = useState({
    day: 'em_breve',
    active: false,
    flyer: "",
    type: 'em_breve'
  });

  
  const [message, setMessage] = useState('');
  const [customEvents, setCustomEvents] = useState([]); // Estado para armazenar eventos personalizados

  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal
  const [customEvent, setCustomEvent] = useState({
    eventDate: '',
    day: '',
    pistaActive: false,
    pistaPrice: 0,
    camaroteActive: false,
    camarotePrice: 0,
    camaroteOpenActive: false,
    camaroteOpenPrice: 0,
    flyer: null
  });
  const [isManageModalOpen, setIsManageModalOpen] = useState(false); // Estado para o modal de gerenciamento

  const fetchCustomEvents = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/custom-events`)
      .then(response => {
        setCustomEvents(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar eventos personalizados:', error);
        setMessage('Erro ao carregar eventos personalizados.');
      });
  };


  const openManageModal = () => {
    fetchCustomEvents(); // Carregar eventos personalizados ao abrir o modal
    setIsManageModalOpen(true);
  };

  // Fechar o modal de gerenciamento
  const closeManageModal = () => {
    setIsManageModalOpen(false);
  };


    // Função para excluir um evento personalizado
    const deleteCustomEvent = (eventId) => {
      console.log("Tentando excluir o evento com ID:", eventId); // Verificar se o evento correto está sendo passado
      if (window.confirm('Tem certeza que deseja excluir este evento?')) {
        axios.delete(`${process.env.REACT_APP_API_URL}/custom-events/${eventId}`)
          .then((response) => {
            console.log("Resposta do servidor ao excluir:", response.data); // Verificar a resposta do servidor
            setCustomEvents(customEvents.filter(event => event._id !== eventId));
            setMessage('Evento excluído com sucesso.');
          })
          .catch(error => {
            console.error('Erro ao excluir o evento:', error.response ? error.response.data : error.message); // Log mais detalhado do erro
            setMessage('Erro ao excluir o evento.');
          });
      }
    };
    
  


  const handleEventChange = (day, key, value) => {
    if (day === 'em_breve') {
      setComingSoonEvent({ ...comingSoonEvent, [key]: value });
    } else {
      const setEvent = day === 'friday' ? setFridayEvent : setSaturdayEvent;
      const event = day === 'friday' ? fridayEvent : saturdayEvent;
      setEvent({ ...event, [key]: value });
    }
  };

  const handleFileChange = (day, e) => {
    if (day === 'em_breve') {
      setComingSoonEvent({ ...comingSoonEvent, flyer: e.target.files[0] });
    } else {
      const setEvent = day === 'friday' ? setFridayEvent : setSaturdayEvent;
      const event = day === 'friday' ? fridayEvent : saturdayEvent;
      setEvent({ ...event, flyer: e.target.files[0] });
    }
  };

  const validateEvent = (event) => {
    if (event.day !== 'em_breve') {
      if (event.active && (!event.flyer || (!event.pistaActive && !event.camaroteActive && !event.camaroteOpenActive))) {
        return false;
      }
    } else {
      if (event.active && !event.flyer) {
        return false;
      }
    }
    return true;
  };

  const submitEvents = () => {
    if (!fridayEvent.active && !saturdayEvent.active && !comingSoonEvent.active) {
      // Se nenhum evento estiver ativo, limpar o banco de dados
      axios.post(`${process.env.REACT_APP_API_URL}/events/clear-all`)
        .then(() => {
          setMessage('Todos os eventos foram removidos com sucesso.');
        })
        .catch(error => {
          setMessage('Erro ao limpar eventos.');
          console.error('Erro ao limpar eventos:', error);
        });
      return;
    }

    const events = [];
    if (fridayEvent.active) {
      if (!validateEvent(fridayEvent)) {
        setMessage('Erro: Evento de sexta-feira precisa de uma imagem e pelo menos uma opção de preço.');
        return;
      }
      events.push(fridayEvent);
    }
    if (saturdayEvent.active) {
      if (!validateEvent(saturdayEvent)) {
        setMessage('Erro: Evento de sábado precisa de uma imagem e pelo menos uma opção de preço.');
        return;
      }
      events.push(saturdayEvent);
    }
    if (comingSoonEvent.active) {
      if (!validateEvent(comingSoonEvent)) {
        setMessage('Erro: Evento "EM BREVE" precisa de uma imagem.');
        return;
      }
      events.push(comingSoonEvent);
    }

    // Primeiro, apagar todos os eventos
    axios.post(`${process.env.REACT_APP_API_URL}/events/clear-all`)
      .then(() => {
        // Depois, enviar os eventos para o backend
        return Promise.all(events.map(event => {
          const formData = new FormData();
          formData.append('day', event.day);
          formData.append('type', event.type || 'regular');
          if (event.pistaActive) formData.append('pistaPrice', event.pistaPrice);
          if (event.camaroteActive) formData.append('camarotePrice', event.camarotePrice);
          if (event.camaroteOpenActive) formData.append('camaroteOpenPrice', event.camaroteOpenPrice);
          formData.append('flyer', event.flyer);

          return axios.post(`${process.env.REACT_APP_API_URL}/events`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }));
      })
      .then(responses => {
        setMessage('Eventos salvos com sucesso!');
        console.log('Eventos salvos:', responses.map(response => response.data));
      })
      .catch(error => {
        setMessage('Erro ao salvar eventos.');
        console.error('Erro ao salvar eventos:', error);
      });
  };

  const clearNames = (day) => {
    if (window.confirm(`ATENÇÃO, TODOS OS NOMES DA LISTA DE ${day.toUpperCase()} SERÃO APAGADOS. Você deseja continuar?`)) {
      axios.post(`${process.env.REACT_APP_API_URL}/events/clear-names`, { day })
        .then(response => {
          alert(response.data.message);
        })
        .catch(error => {
          alert('Erro ao apagar a lista de nomes.');
          console.error('Erro ao apagar a lista de nomes:', error);
        });
    }
  };

  const exportNames = (day) => {
    window.open(`${process.env.REACT_APP_API_URL}/events/export-names/${day}`, '_blank');
  };

  const exportNamesToExcel = (day) => {
    window.open(`${process.env.REACT_APP_API_URL}/events/export-names-excel/${day}`, '_blank');
  };

  const handleCustomEventChange = (key, value) => {
    setCustomEvent({ ...customEvent, [key]: value });
  };

  const handleCustomFileChange = (e) => {
    setCustomEvent({ ...customEvent, flyer: e.target.files[0] });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  // Função para limpar os nomes do evento personalizado
const clearCustomEventNames = () => {
  if (window.confirm("ATENÇÃO, TODOS OS NOMES DO EVENTO PERSONALIZADO SERÃO APAGADOS. Você deseja continuar?")) {
    axios.post(`${process.env.REACT_APP_API_URL}/custom-events/clear-names`)
      .then(response => {
        alert(response.data.message);
      })
      .catch(error => {
        alert('Erro ao apagar a lista de nomes do evento personalizado.');
        console.error('Erro ao apagar a lista de nomes:', error);
      });
  }
};

// Função para exportar os nomes do evento personalizado em PDF
const exportCustomEventNames = () => {
  window.open(`${process.env.REACT_APP_API_URL}/custom-events/export-names`, '_blank');
};

// Função para exportar os nomes do evento personalizado em Excel
const exportCustomEventNamesToExcel = () => {
  window.open(`${process.env.REACT_APP_API_URL}/custom-events/export-names-excel`, '_blank');
};



  const handleSaveCustomEvent = () => {
    // Verificar se os campos essenciais estão preenchidos
    if (!customEvent.eventDate || !customEvent.day || !customEvent.flyer) {
      setMessage('Preencha todos os campos obrigatórios.');
      return;
    }
  
    const formData = new FormData();
    formData.append('eventDate', customEvent.eventDate);
    formData.append('day', customEvent.day);
    formData.append('flyer', customEvent.flyer);
  
    if (customEvent.pistaActive) {
      formData.append('pistaPrice', customEvent.pistaPrice);
    }
    if (customEvent.camaroteActive) {
      formData.append('camarotePrice', customEvent.camarotePrice);
    }
    if (customEvent.camaroteOpenActive) {
      formData.append('camaroteOpenPrice', customEvent.camaroteOpenPrice);
    }
  
    axios.post(`${process.env.REACT_APP_API_URL}/custom-events`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    .then(response => {
      setMessage('Evento personalizado salvo com sucesso!');
      console.log('Evento salvo:', response.data);
      closeModal(); // Fechar o modal após o salvamento
    })
    .catch(error => {
      setMessage('Erro ao salvar o evento.');
      console.error('Erro ao salvar o evento:', error);
    });
  };
  

  if (!isAuthenticated) {
    return <AdminLogin onLogin={setIsAuthenticated} />;
  }

  return (
    <div>
      <h1>Administração de Eventos</h1>
      <div>
        <label>
          Evento na Sexta
          <input
            type="checkbox"
            checked={fridayEvent.active}
            onChange={() => handleEventChange('friday', 'active', !fridayEvent.active)}
          />
        </label>
        {fridayEvent.active && (
          <div>
            <input
              type="file"
              onChange={(e) => handleFileChange('friday', e)}
            />
            <label>
              <input
                type="checkbox"
                checked={fridayEvent.pistaActive}
                onChange={(e) => handleEventChange('friday', 'pistaActive', e.target.checked)}
              />
              Pista
              {fridayEvent.pistaActive && (
                <input
                  type="number"
                  value={fridayEvent.pistaPrice}
                  onChange={(e) => handleEventChange('friday', 'pistaPrice', e.target.value)}
                  placeholder="Preço da Pista"
                />
              )}
            </label>
            <label>
              <input
                type="checkbox"
                checked={fridayEvent.camaroteActive}
                onChange={(e) => handleEventChange('friday', 'camaroteActive', e.target.checked)}
              />
              Camarote
              {fridayEvent.camaroteActive && (
                <input
                  type="number"
                  value={fridayEvent.camarotePrice}
                  onChange={(e) => handleEventChange('friday', 'camarotePrice', e.target.value)}
                  placeholder="Preço do Camarote"
                />
              )}
            </label>
            <label>
              <input
                type="checkbox"
                checked={fridayEvent.camaroteOpenActive}
                onChange={(e) => handleEventChange('friday', 'camaroteOpenActive', e.target.checked)}
              />
              Camarote Open
              {fridayEvent.camaroteOpenActive && (
                <input
                  type="number"
                  value={fridayEvent.camaroteOpenPrice}
                  onChange={(e) => handleEventChange('friday', 'camaroteOpenPrice', e.target.value)}
                  placeholder="Preço do Camarote Open"
                />
              )}
            </label>
          </div>
        )}
      </div>
      <div>
        <label>
          Evento no Sábado
          <input
            type="checkbox"
            checked={saturdayEvent.active}
            onChange={() => handleEventChange('saturday', 'active', !saturdayEvent.active)}
          />
        </label>
        {saturdayEvent.active && (
          <div>
            <input
              type="file"
              onChange={(e) => handleFileChange('saturday', e)}
            />
            <label>
              <input
                type="checkbox"
                checked={saturdayEvent.pistaActive}
                onChange={(e) => handleEventChange('saturday', 'pistaActive', e.target.checked)}
              />
              Pista
              {saturdayEvent.pistaActive && (
                <input
                  type="number"
                  value={saturdayEvent.pistaPrice}
                  onChange={(e) => handleEventChange('saturday', 'pistaPrice', e.target.value)}
                  placeholder="Preço da Pista"
                />
              )}
            </label>
            <label>
              <input
                type="checkbox"
                checked={saturdayEvent.camaroteActive}
                onChange={(e) => handleEventChange('saturday', 'camaroteActive', e.target.checked)}
              />
              Camarote
              {saturdayEvent.camaroteActive && (
                <input
                  type="number"
                  value={saturdayEvent.camarotePrice}
                  onChange={(e) => handleEventChange('saturday', 'camarotePrice', e.target.value)}
                  placeholder="Preço do Camarote"
                />
              )}
            </label>
            <label>
              <input
                type="checkbox"
                checked={saturdayEvent.camaroteOpenActive}
                onChange={(e) => handleEventChange('saturday', 'camaroteOpenActive', e.target.checked)}
              />
              Camarote Open
              {saturdayEvent.camaroteOpenActive && (
                <input
                  type="number"
                  value={saturdayEvent.camaroteOpenPrice}
                  onChange={(e) => handleEventChange('saturday', 'camaroteOpenPrice', e.target.value)}
                  placeholder="Preço do Camarote Open"
                />
              )}
            </label>
          </div>
        )}
      </div>
      <div>
        <label>
          Evento EM BREVE
          <input
            type="checkbox"
            checked={comingSoonEvent.active}
            onChange={() => handleEventChange('em_breve', 'active', !comingSoonEvent.active)}
          />
        </label>
        {comingSoonEvent.active && (
          <div>
            <input
              type="file"
              onChange={(e) => handleFileChange('em_breve', e)}
            />
          </div>
        )}
      </div>
      <div>
        <button onClick={openModal}>
          Adicionar evento personalizado
        </button>

        <button onClick={openManageModal}>
          Gerenciar Eventos Personalizados
      </button>
      </div>
      <button onClick={submitEvents}>Salvar Eventos</button>
      {message && <span>{message}</span>}
      <hr />

      <h2>Gerenciar Listas de Nomes</h2>
      <button onClick={() => clearNames('friday')}>Limpar Lista de Sexta-feira</button>
      <button onClick={() => clearNames('saturday')}>Limpar Lista de Sábado</button>
      <button onClick={() => exportNames('friday')}>Exportar Nomes Confirmados para o Evento de Sexta-feira</button>
      <button onClick={() => exportNames('saturday')}>Exportar Nomes Confirmados para o Evento de Sábado</button>
      <button onClick={() => exportNamesToExcel('friday')}>Exportar Nomes para o Evento de Sexta-feira (.xlsx)</button>
      <button onClick={() => exportNamesToExcel('saturday')}>Exportar Nomes para o Evento de Sábado (.xlsx)</button>
      <h3>Evento Personalizado</h3>
      <button onClick={clearCustomEventNames}>Limpar Lista de Evento Personalizado</button>
      <button onClick={exportCustomEventNames}>Exportar Nomes Confirmados para o Evento Personalizado (PDF)</button>
      <button onClick={exportCustomEventNamesToExcel}>Exportar Nomes para o Evento Personalizado (.xlsx)</button>

      {/* Modal de Adicionar Evento Personalizado */}
      <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Adicionar Evento Personalizado"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      color: 'black',
      padding: '25px',
      transform: 'translate(-50%, -50%)'
    }
  }}
>
  <h2>Evento Personalizado</h2>
  
  {/* Data do Evento */}
  <div>
    <label>Data do Evento:</label>
    <input
      type="date"
      value={customEvent.eventDate}
      onChange={(e) => handleCustomEventChange('eventDate', e.target.value)}
    />
  </div>

  {/* Dia do Evento */}
  <div>
    <label>Dia do Evento:</label>
    <input
      type="text"
      value={customEvent.day}
      placeholder="Ex: Sexta-feira"
      onChange={(e) => handleCustomEventChange('day', e.target.value)}
    />
  </div>

  {/* Pista */}
  <div>
    <label>
      <input
        type="checkbox"
        checked={customEvent.pistaActive}
        onChange={(e) => handleCustomEventChange('pistaActive', e.target.checked)}
      />
      Pista
    </label>
    {customEvent.pistaActive && (
      <input
        type="number"
        value={customEvent.pistaPrice}
        onChange={(e) => handleCustomEventChange('pistaPrice', e.target.value)}
        placeholder="Preço da Pista"
      />
    )}
  </div>

  {/* Camarote */}
  <div>
    <label>
      <input
        type="checkbox"
        checked={customEvent.camaroteActive}
        onChange={(e) => handleCustomEventChange('camaroteActive', e.target.checked)}
      />
      Camarote
    </label>
    {customEvent.camaroteActive && (
      <input
        type="number"
        value={customEvent.camarotePrice}
        onChange={(e) => handleCustomEventChange('camarotePrice', e.target.value)}
        placeholder="Preço do Camarote"
      />
    )}
  </div>

  {/* Camarote Open */}
  <div>
    <label>
      <input
        type="checkbox"
        checked={customEvent.camaroteOpenActive}
        onChange={(e) => handleCustomEventChange('camaroteOpenActive', e.target.checked)}
      />
      Camarote Open
    </label>
    {customEvent.camaroteOpenActive && (
      <input
        type="number"
        value={customEvent.camaroteOpenPrice}
        onChange={(e) => handleCustomEventChange('camaroteOpenPrice', e.target.value)}
        placeholder="Preço do Camarote Open"
      />
    )}
  </div>

  {/* Imagem do Evento */}
  <div>
    <label>Imagem do Evento:</label>
    <input type="file" onChange={handleCustomFileChange} />
  </div>

  <button onClick={closeModal}>Fechar</button>
  <button onClick={handleSaveCustomEvent}>Salvar</button>

</Modal>



<Modal
        isOpen={isManageModalOpen}
        onRequestClose={closeManageModal}
        contentLabel="Gerenciar Eventos Personalizados"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            color: 'black',
            padding: '25px',
            transform: 'translate(-50%, -50%)'
          }
        }}
      >
        <h2>Gerenciar Eventos Personalizados</h2>

        {/* Listar eventos personalizados */}
        {customEvents.length > 0 ? (
          <ul>
            {customEvents.map(event => (
              <li key={event._id} style={{ marginBottom: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div>
                    <p><strong>Data:</strong> {event.eventDate}</p>
                    <p><strong>Dia:</strong> {event.day}</p>
                    <p><strong>Pista:</strong> {event.pistaPrice ? `R$${event.pistaPrice}` : 'N/A'}</p>
                    <p><strong>Camarote:</strong> {event.camarotePrice ? `R$${event.camarotePrice}` : 'N/A'}</p>
                    <p><strong>Camarote Open:</strong> {event.camaroteOpenPrice ? `R$${event.camaroteOpenPrice}` : 'N/A'}</p>
                  </div>
                  <button
                    onClick={() => deleteCustomEvent(event._id)}
                    style={{ backgroundColor: 'red', color: 'white', padding: '5px' }}
                  >
                    Excluir
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Nenhum evento personalizado disponível.</p>
        )}

        <button onClick={closeManageModal}>Fechar</button>
      </Modal>

    </div>
  );
}

export default AdminPage;
