import React from 'react';

function NameList({ names, onRemove }) {
  return (
    <ul>
      {names.map((name, index) => (
        <li key={index}>
          {name} <button onClick={() => onRemove(index)}>Remover</button>
        </li>
      ))}
    </ul>
  );
}

export default NameList;
