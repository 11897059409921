import React, { useState } from 'react';

function AddName({ onAdd }) {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s+[A-Za-zÀ-ÖØ-öø-ÿ]+)*\s?$/;

    if (nameRegex.test(name)) {
      onAdd(name);
      setName('');
      setError('');
    } else {
      setError('Por favor, insira seu nome completo (IDENTICO AO RG).');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Digite seu nome completo (IDENTICO AO RG)"
      />
      <button type="submit">Adicionar</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </form>
  );
}

export default AddName;
