import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './ConfirmationPage.css'; // Assuming the CSS is in this file

function ConfirmationPage() {
  const [qrCode, setQrCode] = useState('');
  const [totalPaidAmount, setTotalPaidAmount] = useState('');
  const [qrCodeBase64, setQrCodeBase64] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);

  // useRef to store the interval ID so we can clear it later
  const statusIntervalRef = useRef(null);

  // Function to check payment status
  const checkPaymentStatus = async () => {
    if (!paymentId) return;
    try {
      const response = await axios.get(`https://api.mercadopago.com/v1/payments/${paymentId}`, {
        headers: {
          'Authorization': `Bearer APP_USR-1923560273176252-062722-0be379e0088c3bd981c0aefbb6da8316-241870472`
        },
      });
      const paymentStatus = response.data.status;

      if (paymentStatus === 'approved') {
        setPaymentConfirmed(true);
        clearInterval(statusIntervalRef.current); // Stop checking once payment is confirmed
      }
    } catch (error) {
      console.error('Erro ao verificar o status do pagamento:', error);
    }
  };

  useEffect(() => {
    // Retrieve the values from localStorage
    const storedQrCode = localStorage.getItem('qr_code');
    const storedTotalPaidAmount = localStorage.getItem('total_paid_amount');
    const storedQrCodeBase64 = localStorage.getItem('qr_code_base64');
    const storedPaymentId = localStorage.getItem('payment_id');

    if (storedQrCode && storedTotalPaidAmount && storedQrCodeBase64 && storedPaymentId) {
      setQrCode(storedQrCode);
      setTotalPaidAmount(storedTotalPaidAmount);
      setQrCodeBase64(storedQrCodeBase64);
      setPaymentId(storedPaymentId);
    }

    // Start checking the payment status every second
    statusIntervalRef.current = setInterval(() => {
      checkPaymentStatus();
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(statusIntervalRef.current);
  }, [paymentId]);

  // Display a success message if the payment is confirmed
  if (paymentConfirmed) {
    return (
      <div className="confirmation-container">
        <h1 className="confirmation-title">Ingressos Edub</h1>
        <div className="confirmation-success">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
            <path fill="#4caf50" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
            <path fill="#ccff90" d="M34.602,14.602L21,28.199l-5.602-5.598l-2.797,2.797L21,33.801l16.398-16.402L34.602,14.602z"></path>
          </svg>
          <p>Pagamento realizado com sucesso! O nome foi inserido na lista, agora apenas apresente o documento na entrada do evento!</p>
        </div>
      </div>
    );
  }

  // If payment is not yet confirmed, show the payment instructions
  return (
    <div className="confirmation-container">
      <h1 className="confirmation-title">Ingressos Edub</h1>
      <p className="confirmation-subtitle">Pague R$ {totalPaidAmount} via Pix</p>
      <p className="confirmation-deadline">Vencimento: 15 minutos</p>

      {qrCodeBase64 && (
        <div className="confirmation-qr-container">
          <img className="confirmation-qr" src={`data:image/png;base64,${qrCodeBase64}`} alt="QR Code" />
        </div>
      )}

      <div className="confirmation-code">
        <p>Código de pagamento:</p>
        <input className="confirmation-code-input" value={qrCode} readOnly />
      </div>

      <div className="confirmation-instructions">
        <p>Como pagar?</p>
        <ol>
          <li>Entre no app ou site do seu banco e escolha a opção de pagamento via Pix.</li>
          <li>Escaneie o código QR ou copie e cole o código de pagamento.</li>
          <li>Pronto! O pagamento será creditado na hora e você receberá um e-mail de confirmação.</li>
        </ol>
      </div>
    </div>
  );
}

export default ConfirmationPage;
