import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddName from '../components/AddName';
import NameList from '../components/NameList';
import EventImage from '../components/EventImage';
import Header from '../components/Header';
import logo from './now@hall-logotipo-02branco.png';
import '.././App.css';

function RegistrationPage() {
  const [names, setNames] = useState([]);
  const [events, setEvents] = useState([]);
  const [customEvent, setCustomEvent] = useState(null); // Novo estado para eventos personalizados
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log("REACT_APP_API_URL:", process.env.REACT_APP_API_URL);
    console.log("REACT_APP_IMAGE_URL:", process.env.REACT_APP_IMAGE_URL);

    // Buscar eventos ativos
    axios.get(`${process.env.REACT_APP_API_URL}/events`)
      .then((response) => {
        console.log("Eventos carregados:", response.data);
        const filteredEvents = response.data.filter((event) => event.flyer && event.active);
        // Ordenar eventos para garantir que o evento de sexta seja exibido primeiro
        const sortedEvents = filteredEvents.sort((a, b) => {
          if (a.day === 'friday') return -1;
          if (b.day === 'friday') return 1;
          return 0;
        });
        setEvents(sortedEvents);
      })
      .catch(error => {
        console.error("Houve um erro ao buscar os eventos!", error);
      });

    // Buscar evento personalizado
    axios.get(`${process.env.REACT_APP_API_URL}/custom-events`)
      .then((response) => {
        if (response.data.length > 0) {
          setCustomEvent(response.data[0]); // Exibir apenas o primeiro evento personalizado
        }
      })
      .catch(error => {
        console.error("Erro ao buscar o evento personalizado:", error);
      });
  }, []);

  const addName = (name) => {
    setNames([...names, name]);
  };

  const removeName = (index) => {
    setNames(names.filter((_, i) => i !== index));
  };

  const handleDaySelection = (day) => {
    setSelectedDays((prevSelectedDays) => {
      if (prevSelectedDays.includes(day)) {
        const updatedOptions = { ...selectedOptions };
        delete updatedOptions[day];
        setSelectedOptions(updatedOptions);
        return prevSelectedDays.filter(selectedDay => selectedDay !== day);
      } else {
        return [...prevSelectedDays, day];
      }
    });
  };

  const handleOptionChange = (day, option) => {
    setSelectedOptions(prevSelectedOptions => ({
      ...prevSelectedOptions,
      [day]: option
    }));
  };

  const generateTickets = async () => {
    if (selectedDays.length === 0) {
      alert("Por favor, selecione pelo menos um evento.");
      return;
    }

    if (names.length === 0) {
      alert("Por favor, adicione o nome completo.");
      return;
    }

    if (selectedDays.some(day => !selectedOptions[day])) {
      alert("Por favor, selecione uma opção de ingresso para cada dia.");
      return;
    }

    try {
      // Prefixar os nomes com a categoria do ingresso
      const prefixedNames = names.map(name => {
        const prefix = selectedDays.map(day => {
          const option = selectedOptions[day];
          if (option === 'pista') return `PISTA - ${name}`;
          if (option === 'camarote') return `CAMAROTE - ${name}`;
          if (option === 'camaroteOpen') return `CAMAROTE OPEN - ${name}`;
        });
        return prefix.join(' e ');  // Join para considerar múltiplos dias com categorias diferentes
      });

      // Calcular o valor total dos ingressos
      const totalAmount = selectedDays.reduce((total, day) => {
        const event = events.find(event => event.day === day) || customEvent;
        if (event) {
          const selectedOption = selectedOptions[day];
          const price = event[`${selectedOption}Price`];
          return total + (price ? price * names.length : 0);
        }
        return total;
      }, 0);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/create-pix`, {
        transaction_amount: totalAmount,
        description: `Ingressos para o evento de ${selectedDays.join(', ')}`,
        email: 'email@example.com',
        identificationType: 'CPF',
        identificationNumber: '40178012858',
        // identificationNumber: '14054009603',
        names: prefixedNames,
        day: selectedDays
      });

      const qrCode = response.data.point_of_interaction?.transaction_data?.qr_code;
      const totalPaidAmount = response.data.transaction_details?.total_paid_amount;
      const qrCodeBase64 = response.data.point_of_interaction?.transaction_data?.qr_code_base64;
      const paymentId = response.data.id;

      // Armazenar os valores no localStorage, se não forem undefined
      if (qrCode && totalPaidAmount && qrCodeBase64) {
        localStorage.setItem('qr_code', qrCode);
        localStorage.setItem('total_paid_amount', totalPaidAmount);
        localStorage.setItem('qr_code_base64', qrCodeBase64);
        localStorage.setItem('payment_id', paymentId);
      } else {
        console.error('Alguns dos valores estão undefined');
      }

      console.log("Resposta do backend:", response.data);

      const transactionData = response.data?.point_of_interaction?.transaction_data;

      if (transactionData) {
        console.log("QR Code URL:", transactionData.qr_code_base64);
        console.log("Payment URL:", transactionData.ticket_url);
        alert("Pagamento criado com sucesso!");

        // Redirecionar para a página de pagamento
        navigate('/confirmation');
      } else {
        console.error("Dados de transação inválidos na resposta");
        alert("Houve um erro ao criar o pagamento!");
      }
    } catch (error) {
      console.error("Houve um erro ao criar o pagamento!", error);
      alert("Houve um erro ao criar o pagamento!");
    }
  };

  return (
    <div className="container">
      <img src={logo} alt="Logo" className="logo" />
      <Header text={events.some(event => event.day === 'friday' || event.day === 'saturday') ? "GARANTA SEU INGRESSO" : "NENHUM EVENTO DISPONÍVEL NO MOMENTO"} />

      {events.length > 0 ? (
        events.map((event) => (
          <div key={event._id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <h2>{event.day === 'friday' ? 'Sexta-feira' : event.day === 'saturday' ? 'Sábado' : 'EM BREVE'}</h2>
            <EventImage className="event-image" src={`${process.env.REACT_APP_IMAGE_URL}${event.flyer}`} />
            {event.pistaPrice && <p><b>Preço Pista:</b> R${event.pistaPrice}</p>}
            {event.camarotePrice && <p><b>Preço Camarote:</b> R${event.camarotePrice}</p>}
            {event.camaroteOpenPrice && <p><b>Preço Camarote Open:</b> R${event.camaroteOpenPrice}</p>}
          </div>
        ))
      ) : (
        <p>Aguarde Novidades...</p>
      )}

      {/* Exibir o evento personalizado se existir */}
      {customEvent && (
        <div key={customEvent._id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <h2> {new Date(customEvent.eventDate).toLocaleDateString()}</h2>
          <EventImage className="event-image" src={`${process.env.REACT_APP_IMAGE_URL}${customEvent.flyer}`} />
          {customEvent.pistaPrice && <p><b>Preço Pista:</b> R${customEvent.pistaPrice}</p>}
          {customEvent.camarotePrice && <p><b>Preço Camarote:</b> R${customEvent.camarotePrice}</p>}
          {customEvent.camaroteOpenPrice && <p><b>Preço Camarote Open:</b> R${customEvent.camaroteOpenPrice}</p>}
        </div>
      )}

      {/* Seção GARANTIR MINHA VAGA */}
      {events.length > 0 || customEvent ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
          <h2>GARANTIR MINHA VAGA</h2>
          {events.some(event => event.day === 'friday') && (
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedDays.includes('friday')}
                  onChange={() => handleDaySelection('friday')}
                />
                SEXTA-FEIRA
              </label>
              {selectedDays.includes('friday') && (
                <div style={{ marginTop: '10px' }}>
                  {events.some(event => event.day === 'friday' && event.pistaPrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-friday"
                        value="pista"
                        onChange={() => handleOptionChange('friday', 'pista')}
                        checked={selectedOptions['friday'] === 'pista'}
                      />
                      Pista
                    </label>
                  )}
                  {events.some(event => event.day === 'friday' && event.camarotePrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-friday"
                        value="camarote"
                        onChange={() => handleOptionChange('friday', 'camarote')}
                        checked={selectedOptions['friday'] === 'camarote'}
                      />
                      Camarote
                    </label>
                  )}
                  {events.some(event => event.day === 'friday' && event.camaroteOpenPrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-friday"
                        value="camaroteOpen"
                        onChange={() => handleOptionChange('friday', 'camaroteOpen')}
                        checked={selectedOptions['friday'] === 'camaroteOpen'}
                      />
                      Camarote Open
                    </label>
                  )}
                </div>
              )}
            </div>
          )}

          {events.some(event => event.day === 'saturday') && (
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedDays.includes('saturday')}
                  onChange={() => handleDaySelection('saturday')}
                />
                SÁBADO
              </label>
              {selectedDays.includes('saturday') && (
                <div style={{ marginTop: '10px' }}>
                  {events.some(event => event.day === 'saturday' && event.pistaPrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-saturday"
                        value="pista"
                        onChange={() => handleOptionChange('saturday', 'pista')}
                        checked={selectedOptions['saturday'] === 'pista'}
                      />
                      Pista
                    </label>
                  )}
                  {events.some(event => event.day === 'saturday' && event.camarotePrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-saturday"
                        value="camarote"
                        onChange={() => handleOptionChange('saturday', 'camarote')}
                        checked={selectedOptions['saturday'] === 'camarote'}
                      />
                      Camarote
                    </label>
                  )}
                  {events.some(event => event.day === 'saturday' && event.camaroteOpenPrice) && (
                    <label>
                      <input
                        type="radio"
                        name="option-saturday"
                        value="camaroteOpen"
                        onChange={() => handleOptionChange('saturday', 'camaroteOpen')}
                        checked={selectedOptions['saturday'] === 'camaroteOpen'}
                      />
                      Camarote Open
                    </label>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Evento personalizado na seção "GARANTIR MINHA VAGA" */}
          {customEvent && (
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedDays.includes('customEvent')}
                  onChange={() => handleDaySelection('customEvent')}
                />
                {new Date(customEvent.eventDate).toLocaleDateString()} {/* Exibir a data do evento personalizado */}
              </label>
              {selectedDays.includes('customEvent') && (
                <div style={{ marginTop: '10px' }}>
                  {customEvent.pistaPrice && (
                    <label>
                      <input
                        type="radio"
                        name="option-customEvent"
                        value="pista"
                        onChange={() => handleOptionChange('customEvent', 'pista')}
                        checked={selectedOptions['customEvent'] === 'pista'}
                      />
                      Pista
                    </label>
                  )}
                  {customEvent.camarotePrice && (
                    <label>
                      <input
                        type="radio"
                        name="option-customEvent"
                        value="camarote"
                        onChange={() => handleOptionChange('customEvent', 'camarote')}
                        checked={selectedOptions['customEvent'] === 'camarote'}
                      />
                      Camarote
                    </label>
                  )}
                  {customEvent.camaroteOpenPrice && (
                    <label>
                      <input
                        type="radio"
                        name="option-customEvent"
                        value="camaroteOpen"
                        onChange={() => handleOptionChange('customEvent', 'camaroteOpen')}
                        checked={selectedOptions['customEvent'] === 'camaroteOpen'}
                      />
                      Camarote Open
                    </label>
                  )}
                </div>
              )}
            </div>
          )}

          <AddName onAdd={addName} />
          <NameList names={names} onRemove={removeName} />
          <button onClick={generateTickets} style={{ marginTop: '10px' }}>GERAR TICKETS</button>
        </div>
      ) : (
        <p>Aguarde Novidades...</p>
      )}
    </div>
  );
}

export default RegistrationPage;
