import React, { useState } from 'react';

function AdminLogin({ onLogin }) {
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (password === 'opEx=PQGo)d~}Q[') {
      onLogin(true);
    } else {
      alert('Senha incorreta!');
    }
  };

  return (
    <div>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="SENHA DO ADM"
      />
      <button onClick={handleLogin}>Entrar</button>
    </div>
  );
}

export default AdminLogin;
